import React from 'react';
import { Link } from 'react-router-dom';

function Website() {

  return (
    <div>
        <nav className="nav-main">
        <ul className="nav-menu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/login">App Login</Link></li>
            <li><Link to="/team">Founders</Link></li>
            <li><Link to="/tnc">Terms and Conditions</Link></li>
            <li><Link to="/privacypolicy">Privacy Policy</Link></li>
            </ul>
        </nav>
      
      <h1>Dynamic Documents</h1>
      <p>Dynamic documents specializes in creating advanced, user-friendly, and legally compliant digital platforms for securing patient consent in healthcare settings. 
      </p>
      <p>The corporation develops tools that ensure patients fully understand medical procedures, risks, and alternatives before granting their consent. The AI consent. ensures a thorough and patient-centered process. Beyond traditional consent forms, the corporation offers multimedia support, such as videos and interactive modules, to enhance understanding. It also integrates with electronic health records (EHR) systems, streamlining the consent documentation process for healthcare providers. 
      </p>
      <p>Privacy and security are central to the corporation’s operations, employing robust encryption and compliance with regulations like HIPAA and GDPR. Moreover, the AI includes voice-to-text capabilities, allowing patients with disabilities or limited literacy to engage fully in the consent process. 
      </p>
      <p>The corporation will collaborate  with hospitals, clinics, and pharmaceutical companies to customize forms for a wide range of treatments, including surgeries, clinical trials, and routine diagnostics. By using machine learning, the system updates itself continuously, reflecting new medical practices and legal requirements. 
      </p>
      <p>It also includes features that track patient engagement, providing data-driven insights to healthcare providers. With the rise of telemedicine, the corporation’s technology ensures that remote consultations maintain the same standard of informed consent as in-person. By reducing administrative burdens, the technology allows medical staff to focus more on patient care. AI-driven analytics also help identify trends in consent rejections, enabling providers to improve communication and trust. The corporation’s mission is to empower patients while fostering transparency and trust in the healthcare system. Regular feedback from users and providers helps refine its products, ensuring they remain relevant and effective. Ethical considerations are deeply embedded, with the AI designed to avoid bias or coercion.  By blending technology with human-centered design, the corporation is revolutionizing the way healthcare consent is obtained and managed. This innovative approach ultimately contributes to improved patient outcomes and satisfaction.</p>
    </div>
  );
}

export default Website;
