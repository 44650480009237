import React from 'react';
import { Link } from 'react-router-dom';

function TermsAndConditions() {

  return (
    <div>
      <nav className="nav-main">
        <ul className="nav-menu">
            <li><Link to="/">Home</Link></li>
            </ul>
        </nav>
      <h1>Terms and Conditions</h1>
      <p>Welcome to Dynamic Documents LLC (the “Website”). These Terms and Conditions govern your use of the Website and its associated services. By accessing or using the Website, you agree to be bound by these Terms and Conditions. If you do not agree, please do not use the Website.</p>
      
      <h2>1. Definitions</h2>
      <p><strong>1.1</strong> “Website” refers to the Dynamic Documents platform and all associated services.</p>
      <p><strong>1.2</strong> “User” refers to any individual accessing or using the Website.</p>
      <p><strong>1.3</strong> “Healthcare Provider” refers to medical professionals or institutions using the Website to facilitate patient consent.</p>
      
      <h2>2. Purpose of the Website</h2>
      <p>The Website provides tools to facilitate the creation, delivery, and management of medical consent forms for healthcare procedures. It does not provide medical advice or services and is not a substitute for professional medical consultations.</p>
      
      <h2>3. User Responsibilities</h2>
      <p><strong>3.1</strong> Users must provide accurate and complete information when using the Website.</p>
      <p><strong>3.2</strong> Users agree to use the Website in compliance with applicable laws, including privacy and healthcare regulations.</p>
      <p><strong>3.3</strong> Users are responsible for maintaining the confidentiality of their login credentials.</p>
      <p><strong>3.4</strong> Users agree to opt-in to receive messages and/or emails related to the services provided by the Website. This includes notifications, updates, and other relevant communications. Users may unsubscribe from such communications at any time by writing an email to abermanmd@gmail.com</p>

      <h2>4. Privacy and Data Security</h2>
      <p><strong>4.1</strong> The Website collects and processes personal data in accordance with our <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
    Privacy Policy
  </Link>.</p>
      <p><strong>4.2</strong> Data entered on the Website is encrypted and stored securely. However, no system can be completely secure, and users acknowledge the risk of data breaches.</p>
      
      <h2>5. Intellectual Property</h2>
      <p><strong>5.1</strong> All content on the Website, including text, graphics, and software, is owned by Dynamic Documents or licensed to it.</p>
      <p><strong>5.2</strong> Users may not reproduce, distribute, or modify any content without prior written permission.</p>
      
      <h2>6. Disclaimer of Warranties</h2>
      <p><strong>6.1</strong> The Website is provided “as is” and “as available.”</p>
      <p><strong>6.2</strong> Dynamic Documents makes no warranties, expressed or implied, regarding the accuracy, reliability, or completeness of the services.</p>
      
      <h2>7. Limitation of Liability</h2>
      <p><strong>7.1</strong> Dynamic Documents is not responsible for any damages arising from the use of the Website.</p>
      <p><strong>7.2</strong> The Website is not liable for errors in consent forms or misunderstandings between healthcare providers and patients.</p>
      
      <h2>8. Indemnification</h2>
      <p>Users agree to indemnify and hold Dynamic Documents harmless from any claims or damages arising from their use of the Website.</p>
      
      <h2>9. Termination</h2>
      <p>Dynamic Documents reserves the right to terminate access to the Website for any user who violates these Terms and Conditions or engages in unlawful behavior.</p>
      
      <h2>10. Changes to Terms and Conditions</h2>
      <p>Dynamic Documents may update these Terms and Conditions at any time. Users will be notified of changes, and continued use of the Website constitutes acceptance of the updated Terms.</p>
      
      <h2>11. Governing Law</h2>
      <p>These Terms and Conditions are governed by the laws of [Jurisdiction/State/Country]. Any disputes will be resolved in courts located in [Jurisdiction].</p>
      
      <p>By using the Website, you acknowledge that you have read, understood, and agree to the Terms and Conditions.</p>

      <hr />
    </div>
  );
}

export default TermsAndConditions;
