import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div>
        <nav className="nav-main">
            <ul className="nav-menu">
            <li><Link to="/">Home</Link></li>
            </ul>
        </nav>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> December 2024</p>
      <p><strong>Last Updated:</strong> December 2024</p>

      <p>
        Dynamic Documents LLC ("we," "our," or "us") is committed to protecting your privacy.
        This Privacy Policy outlines how we collect, use, share, and safeguard your personal 
        information when you use our website and services (collectively, the “Services”).
        By accessing or using the Services, you consent to the practices described in this policy.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>1.1 Information You Provide</h3>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, and other details you submit during registration or service use.</li>
        <li><strong>Healthcare Information:</strong> Data related to medical consent forms or other healthcare-related information provided by users.</li>
        <li><strong>Payment Information:</strong> If applicable, we may collect billing details for paid services.</li>
      </ul>
      <h3>1.2 Information Collected Automatically</h3>
      <ul>
        <li><strong>Device Information:</strong> IP address, browser type, operating system, and device details.</li>
        <li><strong>Usage Data:</strong> Pages visited, time spent on the website, and other analytical data to improve user experience.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to track your activity and store preferences.</li>
      </ul>
      <h3>1.3 Third-Party Information</h3>
      <p>We may receive information about you from third parties, such as healthcare providers or integrated services.</p>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>To provide, operate, and improve our Services.</li>
        <li>To facilitate the creation and delivery of consent forms.</li>
        <li>To communicate with you about your account, updates, or service changes.</li>
        <li>To send marketing communications, subject to your preferences.</li>
        <li>To comply with legal obligations and enforce our terms of service.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell your personal information. We may share your data in the following circumstances:</p>
      <ul>
        <li><strong>With Service Providers:</strong> To assist with service delivery, payment processing, or analytics.</li>
        <li><strong>With Healthcare Providers:</strong> As necessary to facilitate patient consent and related services.</li>
        <li><strong>For Legal Compliance:</strong> To comply with applicable laws, regulations, or legal processes.</li>
        <li><strong>With Your Consent:</strong> When you explicitly authorize sharing your information.</li>
      </ul>

      <h2>4. Data Retention</h2>
      <p>We retain your data only for as long as necessary to fulfill the purposes described in this policy or as required by law. After that, your data is deleted or anonymized.</p>

      <h2>5. Data Security</h2>
      <p>We use industry-standard measures to protect your data. However, no method of transmission over the Internet is 100% secure. Users acknowledge the inherent risks of online data sharing.</p>

      <h2>6. Your Rights</h2>
      <p>Depending on your jurisdiction, you may have the following rights:</p>
      <ul>
        <li><strong>Access and Portability:</strong> Request a copy of your data.</li>
        <li><strong>Correction:</strong> Update or amend inaccurate information.</li>
        <li><strong>Deletion:</strong> Request the removal of your data.</li>
        <li><strong>Objection and Restriction:</strong> Limit how we use your data.</li>
        <li><strong>Withdrawal of Consent:</strong> Revoke consent for marketing or other optional data uses.</li>
      </ul>
      <p>To exercise your rights, please contact us at [Insert Contact Email].</p>

      <h2>7. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar technologies to enhance your browsing experience. You can manage your cookie preferences through your browser settings.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. Your continued use of the Services constitutes acceptance of the changes.</p>

      <h2>9. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy or your data, please contact us:
      </p>
      <p>
        <strong>Dynamic Documents LLC</strong><br />
        <strong>Email:</strong>abermanmd@gmail.com<br />
      </p>
    </div>
  );
};

export default PrivacyPolicy;
