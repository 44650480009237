import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`${apiBaseUrl}/api/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      // Remove the token from localStorage
      localStorage.removeItem('token');
      
      // Redirect to the login page
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  // Call handleLogout when the component is rendered
  React.useEffect(() => {
    handleLogout();
  }, []); // Empty dependency array ensures this runs once after the initial render

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default Logout;
